import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {H1, H3, Button, Container, Row, Col} from '@bootstrap-styled/v4';
// import SharedIcon from '../components/icons/SharedIcon';
// import NewsCardHorizontal from '../components/news/NewsCardHorizontal';
// import Banner from '../components/home/Banner';
import Api from '../services/api';
import {navigate} from '@reach/router';
import {formatDate} from '../modules/utils';
import {BASE_BUCKET_URL} from '../constants/global';
import ProjectInterest from '../components/projects/ProjectInterest';
import CardNews from '../components/CardNews/CardNews';
import LazyLoad from 'react-lazyload';
import Skeleton from 'react-loading-skeleton';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

/* SEO */
import SeoEngine from '../components/SeoEngine';
import FormContact from '../components/FormContact/FormContact';

const Image = styled.img`
  background-color: #f0f0f0;
  object-fit: contain !important;
`;

const Headline = styled.div`
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  width: 100vw;

  @media (min-width: 1200px) {
    .wrapper-handeling {
      max-width: 1300px;
    }
  }

  @media (max-width: 1000px) {
    background: #69a2d785;
    margin-top: 4.8rem;
    padding-top: 1rem;

    .create_by_and_datetime {
      padding: 0 1rem !important;
    }
  }

  @media (max-width: 912px) {
    background: #69a2d785;
    margin-top: 4rem;

    .create_by_and_datetime {
      padding: 0 1rem !important;
    }
  }

  .wrapper-handeling {
    width: 100%;
  }
`;

const Title = styled(H1)`
  font-size: 26px !important;
  letter-spacing: 0;
  color: #000000;
  max-width: 724px;
  font-weight: bold;
  margin-bottom: 2rem;

  @media (max-width: 912px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1.4rem;
    margin-top: 1.4rem;
    margin-bottom: 0.7rem;
  }
`;

const Date = styled.span`
  text-transform: capitalize;
  font-size: 0.8rem;
`;

const Text = styled.p`
  letter-spacing: 0;
  color: #444;
  opacity: 1;
  font-size: 16px;

  margin-top: 0 !important;
  margin-bottom: 0px !important;
  line-height: 30px !important;

  & strong,
  em {
    color: #000 !important;
  }

  & > .ql-align-center:nth-child(1) {
    margin-top: 0px !important;
    font-size: 20px !important;
  }

  & .ql-align-justify > strong {
    text-align: left !important;
    margin-bottom: 20px !important;
    display: inline-block;
    margin-top: 20px;
  }
  & .ql-align-justify {
    text-align: justify !important;

    margin-top: 0 !important;
    margin-bottom: 0px !important;
    line-height: 30px !important;

    display: flex;
    align-items: start;
    justify-content: start;
  }
  & .ql-align-center {
    text-align: center !important;

    margin-top: 0 !important;
    margin-bottom: 0px !important;
    line-height: 30px !important;
  }
`;

// const MyContainer = styled(Container)`

//     @media (min-width: 992px){
//       .container, .container-lg, .container-md, .container-sm {
//         max-width: 100% !important;
//         padding: 1rem !important;
//     }
//   }

// `;

const SubTitle = styled(H3)`
  color: #000000;
  padding-left: 1.4rem;
  /* opacity: 0.7; */
  font-size: 24px !important;
  font-weight: bold;

  @media (max-width: 576px) {
    padding-left: 0.8rem;
  }
`;

const Wrapper = styled(Container)`
  padding-top: 3.75rem;
  /* position: relative;
  padding-bottom: 5.375rem; */
`;

const SocialContainer = styled.div`
  background-color: #69a2d785;
  min-width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;

  .title {
    font-size: 32px;
    font-weight: 600;
    margin: 0;
    width: 100%;
    padding: 0 3.5rem;
  }

  .redes {
    display: flex;
    gap: 3rem;

    @media (max-width: 1198px) {
      gap: 2rem;
    }
  }

  .line {
    margin-top: 0.3rem;
    margin-bottom: 2rem;
  }

  @media (max-width: 1000px) {
    min-width: 100%;
    max-width: 100%;
  }
`;

const Presentation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const ImagenContent = styled.div`
  background: #f0f0f0 !important;
  max-height: 460px;
  min-height: 460px;
  min-width: 100%;
  box-sizing: border-box;

  display: flex;

  @media (max-width: 1000px) {
    max-height: 100%;
    min-height: 100%;

    flex-direction: column;
    .image {
      display: none;
    }
  }
`;

//TODO add loading skeleton
const NewsDetail = ({slug, location}) => {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState({});
  const [extraNews, setExtraNews] = useState([]);
  const [project, setProject] = useState({});

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    let request = Api.projects
      .find({
        query: {
          $limit: 1,
          $skip: 4,
        },
      })
      .subscribe(({data}) => {
        setProject(...data);
        setLoading(false);
      });
    return () => request.unsubscribe();
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      let request = Api.blogs.get(slug).subscribe(data => {
        // console.log(data);
        setNews(data);
        setLoading(false);
      });

      return () => request.unsubscribe();
    } catch (e) {
      navigate('/not-found');
    }
  }, [slug]);

  useEffect(() => {
    let request = Api.blogs
      .find({
        query: {
          $limit: 3,
          title_slug: {
            $nin: [slug],
          },
        },
      })
      .subscribe(({data}) => {
        setExtraNews(data);
        setLoading(false);
      });
    return () => request.unsubscribe();
  }, [slug]);

  return (
    <React.Fragment>
      <SeoEngine
        title={'Proyectos - Construcaribe'}
        description={
          'Encuentra todos los proyectos nuevos del atlántico en un solo lugar'
        }
      />
      <Headline>
        <div className="wrapper-handeling">
          <div className="title">
            <Title>{news.title}</Title>
          </div>
          <Presentation>
            <ImagenContent>
              <LazyLoad
                className="image"
                height={460}
                style={{width: '70%'}}
                placeholder={<Skeleton height={460} width="70%" />}>
                <img
                  height={460}
                  width={'100%'}
                  style={{objectFit: 'cover'}}
                  src={`${BASE_BUCKET_URL}${news.cover_image}`}
                  alt="Noticias"
                />
              </LazyLoad>
              <SocialContainer>
                <div className="title">
                  <span>Compartir:</span>
                  <hr className="line" />
                </div>
                <div className="redes">
                  <FacebookShareButton url={location.href} quote={news.title}>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={location.href} title={news.title}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={location.href}
                    title={news.title}
                    separator=":: ">
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={location.href}
                    subject={news.title}
                    body="body">
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </div>
              </SocialContainer>
            </ImagenContent>
          </Presentation>

          {/* -----------------------------`${BASE_BUCKET_URL}${news.author_image}`-------------- */}
          <div className="create_by_and_datetime m-4">
            {/* <Col md={8} className="d-flex justify-content-space-between"> */}
            <div style={{height: '100%'}} className="logo-autor_center">
              <div className="logo_autor">
                <img
                  width={'95%'}
                  style={{objectFit: 'cover', borderRadius:"50%"}}
                  src={`${BASE_BUCKET_URL}${news.author_image}`}
                  alt="Noticias"
                />
              </div>
              <div>
                <div className="create_by">
                  <span>{`Por: ${news.author || 'Construcaribe'}`}</span>
                </div>
                <Date>{formatDate(news.createdAt)}</Date>
              </div>
            </div>
          </div>
        </div>
      </Headline>
      <Container style={{marginTop: '2rem'}}>
        <SeoEngine
          title={`${news.title} - Construcaribe`}
          description={
            news.short_description
              ? `${news.short_description} - Construcaribe`
              : `${news.title} - Construcaribe`
          }
        />
        {/* <Line /> */}
        <Wrapper>
          <Row>
            <Col md={7}>
              <Text
                className="pt-0 mt-0"
                dangerouslySetInnerHTML={{__html: news.content}}
              />
            </Col>

            <Col md={5}>
              <div>
                <SubTitle className="mb-4">
                  Otros artículos que quizá te interesen
                </SubTitle>
                {/* {console.log(extraNews)} */}
                {extraNews.map((e, index) => (
                  // <NewsCardHorizontal key={item.id} item={item} />
                  <CardNews
                    key={index}
                    title={e.title}
                    imagen={e.cover_image}
                    categoria={'Vivienda'}
                    title_slug={e.title_slug}
                    author={e.author}
                    createdAt={e.createdAt}
                  />
                ))}
              </div>
              <Row>
                <FormContact />
              </Row>
            </Col>
          </Row>
          <Row>{project && <ProjectInterest project={project} />}</Row>
        </Wrapper>
      </Container>
    </React.Fragment>
  );
};

export default NewsDetail;
